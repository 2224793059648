import { Icon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function Logo() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Icon
      cursor="pointer"
      onClick={handleClick}
      width="133px"
      height="12px"
      viewBox="0 0 133 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1199 0.746658C26.4223 0.851954 25.8827 1.13493 25.3496 1.67457C24.7903 2.2471 24.4941 2.85913 24.3362 3.78703C24.2046 4.55042 24.2309 8.45948 24.3691 9.13731C24.7245 10.8418 26.0341 12 27.6201 12C28.1465 12 28.6335 11.8947 29.0876 11.6841C29.5877 11.4538 30.3248 10.7101 30.588 10.1573C30.95 9.42029 31.0026 9.01885 31.0421 6.84715C31.0948 3.85942 30.9632 3.02365 30.3051 2.10232C29.5614 1.05596 28.3769 0.555813 27.1199 0.746658ZM28.4624 2.37872C28.8178 2.55641 29.1863 2.95126 29.3443 3.31321C29.5812 3.86601 29.6338 4.37932 29.6338 6.37333C29.6338 8.92014 29.5285 9.40054 28.8573 10.0191C28.4558 10.3877 28.1597 10.5061 27.6398 10.5061C26.6 10.5127 25.9024 9.74933 25.7248 8.41999C25.6195 7.57763 25.6787 4.18847 25.8169 3.72123C26.0209 3.01707 26.4552 2.50376 27.0146 2.29317C27.3831 2.15497 28.1202 2.20104 28.4624 2.37872Z"
        fill="white"
      />
      <path
        d="M39.255 0.75231C38.1955 0.910252 37.4387 1.44989 37.0109 2.35805C36.3594 3.73346 36.649 5.54979 37.6559 6.3395C38.0902 6.6817 38.564 6.85281 39.6499 7.04365C40.7094 7.22792 41.2096 7.43192 41.4465 7.78071C41.8874 8.44538 41.7821 9.51149 41.2161 10.0314C40.6765 10.5381 39.7223 10.6631 38.6956 10.3736C38.2481 10.2486 37.8862 10.0445 37.544 9.70892L37.2742 9.45226L36.8333 9.96557C36.5898 10.2486 36.3923 10.5052 36.3923 10.5315C36.3923 10.6368 37.0833 11.262 37.4124 11.4594C38.4653 12.0912 40.3409 12.1702 41.4399 11.6305C41.8742 11.42 42.4665 10.8869 42.6903 10.4986C42.9535 10.0511 43.1049 9.41936 43.1049 8.76127C43.1049 7.75439 42.8811 7.07656 42.3612 6.50402C41.9598 6.04994 41.4794 5.82618 40.5054 5.64192C40.0711 5.55637 39.5446 5.45107 39.334 5.41159C38.8273 5.30629 38.4127 5.03648 38.2218 4.68111C37.9981 4.25993 38.0112 3.47022 38.2481 3.01614C38.4522 2.62129 38.5772 2.50283 38.9589 2.33173C39.7683 1.97636 40.9003 2.15404 41.6307 2.7529L41.9071 2.98324L42.348 2.47651L42.789 1.9632L42.4336 1.63415C42.0124 1.24588 41.5518 0.982641 41.0582 0.857605C40.5712 0.725986 39.7289 0.67992 39.255 0.75231Z"
        fill="white"
      />
      <path
        d="M62.0905 0.752557C61.4653 0.844689 60.827 1.19348 60.3334 1.72653C59.6227 2.47675 59.3989 3.08878 59.2936 4.5629C59.1686 6.30026 59.2476 8.61673 59.4582 9.44593C59.6885 10.3343 60.3926 11.2623 61.1165 11.6308C62.0708 12.1112 63.2159 12.1178 64.1569 11.6505C64.5847 11.4399 65.1441 10.8937 65.4336 10.4133C65.6639 10.0185 65.9338 9.2814 66.0259 8.79442L66.072 8.57725H65.3612H64.6505L64.5057 9.01817C64.1964 9.96582 63.5186 10.512 62.6631 10.512C61.6167 10.5055 60.906 9.73549 60.748 8.44563C60.6559 7.72173 60.6559 4.91169 60.748 4.26018C60.8467 3.55602 61.031 3.10194 61.3535 2.77289C61.7154 2.40436 62.0576 2.23984 62.5709 2.20694C63.4725 2.14771 64.1832 2.7005 64.4925 3.69422L64.611 4.06933L65.3349 4.08907L66.0588 4.10882L65.9667 3.69422C65.7166 2.57547 65.2757 1.8384 64.5386 1.29877C63.9068 0.831527 62.9592 0.620938 62.0905 0.752557Z"
        fill="white"
      />
      <path
        d="M73.772 0.746659C72.3702 0.963829 71.3502 2.01677 71.0277 3.57645C70.8566 4.37274 70.8632 8.33444 71.0277 9.13731C71.3897 10.8681 72.6861 12 74.2984 12C75.9634 12 77.1874 10.8878 77.6152 8.99253L77.7073 8.57793H76.99H76.2793L76.2003 8.85433C76.016 9.54532 75.6212 10.0784 75.1079 10.335C74.0681 10.8418 72.8967 10.289 72.5084 9.11757C72.3834 8.71613 72.3636 8.50554 72.3373 6.70896C72.3242 5.43884 72.3373 4.557 72.3834 4.20821C72.5677 2.92494 73.285 2.19446 74.3577 2.19446C74.792 2.19446 75.2198 2.37214 75.5356 2.67486C75.7923 2.91836 76.1082 3.51064 76.174 3.85943L76.2201 4.10292H76.9374C77.6481 4.10292 77.6547 4.10292 77.6547 3.94498C77.6547 3.68832 77.3915 2.91836 77.148 2.43795C76.8847 1.9378 76.3714 1.38501 75.9371 1.12835C75.3777 0.799307 74.4695 0.641365 73.772 0.746659Z"
        fill="white"
      />
      <path
        d="M85.341 0.746793C84.2551 0.931058 83.3667 1.61547 82.8863 2.63551C82.5309 3.37915 82.498 3.72136 82.4915 6.34056C82.4915 9.0124 82.5309 9.35461 82.9324 10.1706C83.2022 10.7103 83.9393 11.4539 84.4328 11.6843C85.1238 12.0067 85.9793 12.0857 86.6901 11.9014C87.9404 11.579 88.8947 10.4997 89.1382 9.13744C89.3619 7.90023 89.3224 4.16886 89.0789 3.25412C88.7236 1.9511 87.618 0.924478 86.3742 0.746793C85.8543 0.674403 85.7753 0.674403 85.341 0.746793ZM86.4861 2.29989C87.1968 2.5368 87.664 3.23437 87.7957 4.25441C87.9075 5.12967 87.8417 8.49909 87.7035 8.98608C87.2692 10.5063 85.6503 11.0196 84.6039 9.97322C83.9919 9.36777 83.8866 8.84788 83.8866 6.40637C83.8866 3.91221 83.9787 3.41206 84.5381 2.78687C85.0251 2.24724 85.7819 2.05639 86.4861 2.29989Z"
        fill="white"
      />
      <path
        d="M0 6.36713V11.9346H0.723899H1.4478V9.72997V7.52537H2.65868C4.03409 7.52537 4.4092 7.47272 4.97516 7.19632C6.08075 6.65669 6.70594 5.4129 6.62697 3.93878C6.548 2.52388 5.857 1.45119 4.73825 0.997113C4.39604 0.852333 4.271 0.845753 2.19144 0.82601L0 0.806267V6.36713ZM4.29733 2.45149C4.91593 2.75421 5.19891 3.30701 5.19891 4.20201C5.19891 4.68242 5.17259 4.78771 4.98832 5.16282C4.81722 5.49845 4.70534 5.62349 4.44869 5.78143L4.12622 5.97886L2.78372 5.9986L1.4478 6.01834V4.1362V2.24748L2.71791 2.27381C3.87615 2.30013 4.00777 2.31329 4.29733 2.45149Z"
        fill="white"
      />
      <path
        d="M11.7798 6.37371V11.9346H12.4708H13.1618L13.1749 9.58519L13.1947 7.22923L14.0897 7.20948L14.9913 7.18974L15.9981 9.56545L17.0116 11.9346H17.8474H18.6831L18.5647 11.6516C18.4989 11.5002 18.0119 10.4078 17.4788 9.22324C16.9524 8.03868 16.518 7.05154 16.518 7.03838C16.518 7.01864 16.6957 6.91334 16.9195 6.79489C17.5776 6.43952 18.0514 5.81433 18.2686 5.00488C18.387 4.5508 18.387 3.50444 18.262 3.01745C17.979 1.9316 17.1761 1.10899 16.1758 0.878656C16.0179 0.845753 14.9649 0.812848 13.8396 0.812848H11.7798V6.37371ZM16.11 2.47782C17.1761 3.03061 17.2683 4.85352 16.2614 5.53135C15.906 5.77485 15.6296 5.81433 14.3661 5.81433H13.1618V4.08356C13.1618 3.12932 13.1815 2.32646 13.2144 2.30013C13.2407 2.27381 13.8264 2.26065 14.5109 2.27381C15.7217 2.30013 15.7744 2.30671 16.11 2.47782Z"
        fill="white"
      />
      <path
        d="M48.1721 6.37371V11.9346H51.1993H54.2265V11.1778V10.421H51.8903H49.5541V8.74283V7.0647H51.5613H53.5685V6.3079V5.5511H51.5613H49.5541L49.5673 3.91903L49.587 2.29355L51.9101 2.27381L54.2265 2.26065V1.53675V0.812848H51.1993H48.1721V6.37371Z"
        fill="white"
      />
      <path
        d="M100.819 6.37371V11.9346H102.78C103.853 11.9346 104.946 11.9017 105.196 11.8688C106.354 11.7042 107.209 10.8882 107.459 9.71023C107.558 9.2364 107.552 8.28875 107.44 7.88732C107.269 7.23581 106.854 6.63695 106.374 6.33422L106.15 6.19602L106.407 6.01176C107.229 5.4129 107.591 4.16911 107.302 2.9319C107.111 2.12903 106.63 1.45119 106.025 1.14847C105.439 0.845753 105.13 0.812848 102.912 0.812848H100.819V6.37371ZM105.229 2.49756C105.729 2.78054 105.966 3.22804 105.966 3.88613C105.966 4.61661 105.689 5.1036 105.13 5.36025C104.899 5.46554 104.689 5.48529 103.57 5.48529H102.267V3.86639V2.24748L103.57 2.26723L104.867 2.29355L105.229 2.49756ZM105.4 7.22923C105.643 7.38059 105.768 7.52537 105.92 7.81493C106.275 8.51908 106.156 9.47989 105.65 9.98004C105.261 10.3683 105.182 10.3881 103.669 10.4078L102.267 10.4275V8.70993V6.99231L103.669 7.01206L105.077 7.0318L105.4 7.22923Z"
        fill="white"
      />
      <path
        d="M114.192 6.32106C113.244 9.35486 112.468 11.8556 112.468 11.8819C112.468 11.9148 112.803 11.9346 113.211 11.9346H113.955L114.278 10.8158L114.6 9.69706L116.502 9.71023L118.41 9.72997L118.66 10.5855C118.805 11.0593 118.95 11.5529 118.983 11.6845L119.055 11.9346H119.812C120.562 11.9346 120.562 11.9346 120.529 11.7898C120.503 11.7042 119.727 9.21008 118.792 6.24209L117.094 0.845753L116.508 0.82601L115.923 0.806267L114.192 6.32106ZM117.285 5.79459C117.673 7.12393 117.976 8.22294 117.969 8.2361C117.956 8.24268 117.298 8.24268 116.502 8.2361L115.054 8.21636L115.778 5.79459C116.173 4.46525 116.522 3.3794 116.541 3.3794C116.561 3.3794 116.897 4.47183 117.285 5.79459Z"
        fill="white"
      />
      <path
        d="M125.958 6.37371V11.9346H126.649H127.34L127.353 9.58519L127.373 7.22923L128.268 7.20948L129.17 7.18974L130.177 9.56545L131.19 11.9346H132.026H132.862L132.743 11.6516C132.677 11.5002 132.19 10.4078 131.657 9.22324C131.131 8.03868 130.697 7.05154 130.697 7.03838C130.697 7.01864 130.874 6.91334 131.098 6.79489C131.756 6.43952 132.23 5.81433 132.447 5.00488C132.565 4.5508 132.565 3.50444 132.44 3.01745C132.157 1.9316 131.355 1.10899 130.354 0.878656C130.196 0.845753 129.143 0.812848 128.018 0.812848H125.958V6.37371ZM130.288 2.47782C131.355 3.03061 131.447 4.85352 130.44 5.53135C130.084 5.77485 129.808 5.81433 128.545 5.81433H127.34V4.08356C127.34 3.12932 127.36 2.32646 127.393 2.30013C127.419 2.27381 128.005 2.26065 128.689 2.27381C129.9 2.30013 129.953 2.30671 130.288 2.47782Z"
        fill="white"
      />
    </Icon>
  );
}
