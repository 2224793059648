import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getSelectedLanguage } from "./helpers";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getSelectedLanguage(),
    fallbackLng: getSelectedLanguage(),
    ns: ["translations"],
    defaultNS: "translations",
    react: {
      useSuspense: true,
    },

    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      requestOptions: {
        cache: "no-store",
      },
    },
  });

export default i18n;
