import { Icon } from "@chakra-ui/react";

export function FilledCartIcon(props) {
  const { width, height, ...rest } = props;
  return (
    <Icon
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7216 7.875H8.00271L6.23245 4.70189C6.03375 4.34573 5.65784 4.125 5.25 4.125H3C2.37868 4.125 1.875 4.62868 1.875 5.25C1.875 5.87132 2.37868 6.375 3 6.375H4.5894L6.33644 9.50648L10.3656 18.4213L10.3697 18.4304L10.7246 19.2156L6.6793 23.5306C6.38843 23.8408 6.29738 24.2886 6.44398 24.6878C6.59057 25.087 6.94976 25.3694 7.37229 25.4177L11.0595 25.8391C15.6715 26.3662 20.3286 26.3662 24.9406 25.8391L28.6278 25.4177C29.2451 25.3472 29.6883 24.7896 29.6178 24.1723C29.5472 23.555 28.9896 23.1117 28.3723 23.1823L24.6851 23.6037C20.2428 24.1114 15.7572 24.1114 11.315 23.6037L9.85169 23.4364L12.8208 20.2694C12.8495 20.2388 12.8762 20.207 12.9008 20.1742L14.03 20.3211C15.6124 20.527 17.2119 20.5674 18.8027 20.4415C22.5129 20.1481 25.9515 18.3826 28.3522 15.5386L29.2193 14.5114C29.2484 14.4769 29.2754 14.4407 29.3002 14.4029L30.9163 11.9401C32.0618 10.1945 30.8095 7.875 28.7216 7.875Z"
        fill="white"
      />
      <path
        d="M9.75 27.75C8.50736 27.75 7.5 28.7574 7.5 30C7.5 31.2426 8.50736 32.25 9.75 32.25C10.9926 32.25 12 31.2426 12 30C12 28.7574 10.9926 27.75 9.75 27.75Z"
        fill="white"
      />
      <path
        d="M24 30C24 28.7574 25.0074 27.75 26.25 27.75C27.4926 27.75 28.5 28.7574 28.5 30C28.5 31.2426 27.4926 32.25 26.25 32.25C25.0074 32.25 24 31.2426 24 30Z"
        fill="white"
      />
      <circle cx="27" cy="6" r="6" fill="#FF911C" />
    </Icon>
  );
}
