import { Button, Image, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../components/PageHeading/PageHeading";

export function MenuElement() {
  const { t } = useTranslation("translations");

  return (
    <>
      <PageHeading mt="40px">{t("menu")}</PageHeading>
      <Image
        mt="20px"
        borderRadius="22px"
        src="/assets/images/MenuBg.png"
        w="100%"
        objectFit="contain"
      />
      <Link href={"/menu"}>
        <Button w={{ base: "100%", md: "450px" }} h="50px" mt="28px">
          {t("view-menu")}
        </Button>
      </Link>
    </>
  );
}
