import { Icon } from "@chakra-ui/react";

export function BurgerMenu(props) {
  const { width, height, ...rest } = props;
  return (
    <Icon
      width={width || "32px"}
      height={height || "39px"}
      viewBox="0 0 32 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66675 27.3112L25.3334 27.3112M6.66675 19.2787H25.3334M6.66675 11.2461L17.3334 11.2461"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
