import { Box, Center, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MainDrawer } from "../../components/Header/MainDrawer";
import { CheckoutIcon } from "../../assets/icons/CheckoutIcon";
import { useRecoilState } from "recoil";
import { isOrderPlaceState } from "../../state/isOrderPlaced";
import { Logo } from "../../assets/icons/Logo";

export function CartHeader() {
  const { t } = useTranslation("translations");
  const [isOrderPlaced, setIsOrderPlaced] = useRecoilState(isOrderPlaceState);

  return (
    <Center
      maxW="1175px"
      zindex={800}
      position="absolute"
      right={0}
      left={0}
      top={0}
      mx="auto"
      justifyContent="space-between"
      p={{ base: "16px 20px", md: "20px" }}
      bgColor="#202020"
    >
      {!isOrderPlaced && (
        <>
          <Box display={{ base: "block", md: "none" }}>
            <MainDrawer />
          </Box>
          <Logo />
        </>
      )}

      <Center gap="14px">
        <Link href="/menu">
          {isOrderPlaced ? (
            <Center>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14.9998 19.9201L8.47984 13.4001C7.70984 12.6301 7.70984 11.3701 8.47984 10.6001L14.9998 4.08008"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Text
                position="relative"
                color="white"
                fontFamily="Pt Serif"
                fontWeight={700}
                fontSize="24px"
                fontStyle="italic"
              >
                {t("back-to-menu")}
              </Text>
            </Center>
          ) : (
            <CheckoutIcon />
          )}
        </Link>
      </Center>
    </Center>
  );
}
