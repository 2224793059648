import { Box, Center, Link } from "@chakra-ui/react";
import { Logo } from "../../assets/icons/Logo";
import { MainDrawer } from "./MainDrawer";
import { InstaIcon } from "../../assets/icons/Instagram";
import { GisIcon } from "../../assets/icons/Frame";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";

export function Header() {
  const isMenuPage =
    window !== undefined && window.location.href.includes("menu");

  const { t } = useTranslation("translations");

  return (
    <Center
      justifyContent="space-between"
      p={{ base: "16px 0px", md: "40px 0px" }}
      bgColor={isMenuPage ? "#202020" : "black"}
    >
      <Box display={{ base: "block", md: "none" }}>
        <MainDrawer />
      </Box>
      <Logo />
      <Center display={{ base: "none", md: "flex" }} gap="25px">
        <Link
          color="#A6A6A6"
          fontSize="16px"
          fontWeight={500}
          fontFamily="Sf Pro Display"
          href="/menu"
        >
          {t("menu")}
        </Link>

        <Link
          color="#A6A6A6"
          fontSize="16px"
          fontWeight={500}
          fontFamily="Sf Pro Display"
          href="https://www.myadagio.kz"
        >
          {t("our-wine-library")}
        </Link>
      </Center>
      <Center gap="14px">
        <Link href="https://instagram.com/proseccobar.kz?igshid=MzRlODBiNWFlZA==">
          <InstaIcon w="24px" h="24px" />
        </Link>
        <Link href="https://2gis.kz/almaty/geo/70000001037439322">
          <GisIcon w="24px" h="24px" />
        </Link>
        <LanguageSelector />
      </Center>
    </Center>
  );
}
