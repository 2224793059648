import useSWR from "swr";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import {
  Box,
  Grid,
  GridItem,
  Image,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

export function Atmosphere() {
  const { t } = useTranslation("translations");

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, error } = useSWR(
    `/api/main/atmosphere/?is_for_mobile=${isMobile}`,
  );

  if (isLoading) return <div></div>;
  if (!!error) return <div></div>;
  if (!data) return <></>;

  console.log(data);
  return (
    <Box mt="40px">
      <PageHeading>{t("atmosphere")}</PageHeading>

      {isMobile ? (
        <Box mt="20px">
          <Swiper spaceBetween={20} slidesPerView={"auto"}>
            {data?.map((item, index) => (
              <SwiperSlide key={index} style={{ width: "fit-content" }}>
                <Image
                  key={index}
                  borderRadius="22px"
                  src={item?.image}
                  objectFit="cover"
                  minH="500px"
                  maxH="500px"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      ) : (
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(10, 1fr)"
          gap="15px"
          mt="20px"
        >
          {data?.map((item, index) => (
            <GridItem
              colSpan={index === 0 || index === 3 || index === 4 ? 6 : 4}
              key={index}
            >
              <Image
                borderRadius="22px"
                src={item?.image}
                w="100%"
                objectFit="contain"
              />
            </GridItem>
          ))}
        </Grid>
      )}
    </Box>
  );
}
