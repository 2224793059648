import { Text } from "@chakra-ui/react";

export function PageHeading({ children, ...rest }) {
  return (
    <Text
      {...rest}
      color="white"
      fontFamily="Sf Pro Display"
      fontWeight={700}
      fontSize="28px"
    >
      {children}
    </Text>
  );
}
