import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { BurgerMenu } from "../../assets/icons/BurgerMenu";
import { useRef } from "react";
import { LinksList } from "./LinksList";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";

export function MainDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <>
      <Box ref={btnRef} onClick={onOpen}>
        <BurgerMenu />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bgColor="black">
          <DrawerCloseButton justifyContent="flex-start" left="20px" top="16px">
            <BurgerMenu />
          </DrawerCloseButton>
          <DrawerBody>
            <LinksList />
          </DrawerBody>
          <DrawerFooter justifyContent="flex-start">
            <LanguageSelector />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
