import { Icon } from "@chakra-ui/react";

export function FacebookIcon(props) {
  const { width, height, ...rest } = props;
  return (
    <Icon
      width={width || "25px"}
      height={height || "25px"}
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        d="M14.7916 3.2041C13.5138 3.2041 12.2884 3.71168 11.385 4.61518C10.4815 5.51867 9.97388 6.74407 9.97388 8.02181V10.7041H7.39575C7.26631 10.7041 7.16138 10.809 7.16138 10.9385V14.4801C7.16138 14.6096 7.26631 14.7145 7.39575 14.7145H9.97388V21.9801C9.97388 22.1096 10.0788 22.2145 10.2083 22.2145H13.7499C13.8794 22.2145 13.9843 22.1096 13.9843 21.9801V14.7145H16.5853C16.6928 14.7145 16.7865 14.6413 16.8126 14.537L17.698 10.9953C17.735 10.8474 17.6231 10.7041 17.4707 10.7041H13.9843V8.02181C13.9843 7.8077 14.0693 7.60236 14.2207 7.45097C14.3721 7.29957 14.5775 7.21452 14.7916 7.21452H17.4999C17.6294 7.21452 17.7343 7.10958 17.7343 6.98014V3.43848C17.7343 3.30903 17.6294 3.2041 17.4999 3.2041H14.7916Z"
        fill="white"
      />
    </Icon>
  );
}
