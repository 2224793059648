import { Icon } from "@chakra-ui/react";

export function TrashIcon(props) {
  const { width, height, ...rest } = props;
  return (
    <Icon
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.32308 2.375C7.32308 2.04708 7.58891 1.78125 7.91683 1.78125H11.0835C11.4114 1.78125 11.6772 2.04708 11.6772 2.375V2.96875H15.0418C15.3697 2.96875 15.6356 3.23458 15.6356 3.5625C15.6356 3.89042 15.3697 4.15625 15.0418 4.15625H3.9585C3.63058 4.15625 3.36475 3.89042 3.36475 3.5625C3.36475 3.23458 3.63058 2.96875 3.9585 2.96875H7.32308V2.375Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.94008 6.28962C4.96235 6.08916 5.1318 5.9375 5.33349 5.9375H13.6668C13.8685 5.9375 14.038 6.08916 14.0602 6.28962L14.2187 7.71569C14.5043 10.2862 14.5043 12.8805 14.2187 15.451L14.2031 15.5914C14.0991 16.5276 13.3735 17.2741 12.4406 17.4047C10.4898 17.6778 8.5105 17.6778 6.55971 17.4047C5.62683 17.2741 4.90125 16.5276 4.79723 15.5914L4.78163 15.451C4.49601 12.8805 4.49602 10.2862 4.78163 7.71569L4.94008 6.28962ZM8.51058 9.025C8.51058 8.69708 8.24475 8.43125 7.91683 8.43125C7.58891 8.43125 7.32308 8.69708 7.32308 9.025L7.32308 14.5667C7.32308 14.8946 7.58891 15.1604 7.91683 15.1604C8.24475 15.1604 8.51058 14.8946 8.51058 14.5667L8.51058 9.025ZM11.6772 9.025C11.6772 8.69708 11.4114 8.43125 11.0835 8.43125C10.7556 8.43125 10.4897 8.69708 10.4897 9.025V14.5667C10.4897 14.8946 10.7556 15.1604 11.0835 15.1604C11.4114 15.1604 11.6772 14.8946 11.6772 14.5667V9.025Z"
        fill="white"
      />
    </Icon>
  );
}
