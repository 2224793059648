import { Center, Text } from "@chakra-ui/react";
import { isDeliveryState } from "../../state/isDelivery";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

export function MenuFilter(props) {
  const { t } = useTranslation("translations");
  const [isDelivery, setIsDelivery] = useRecoilState(isDeliveryState);

  const handleRestClick = () => {
    setIsDelivery(false);
  };

  const handleDeliveryClick = () => {
    setIsDelivery(true);
  };

  return (
    <Center
      bgColor="#323030"
      borderRadius="8px"
      w="100%"
      p="2px"
      h={{ base: "36px", md: "46px" }}
      cursor="pointer"
      {...props}
    >
      <Center
        bgColor={isDelivery ? "#FFF" : "#323030"}
        w="100%"
        borderRadius="7px"
        onClick={handleDeliveryClick}
        h="100%"
      >
        <Text
          color={isDelivery ? "#323030" : "#FFF"}
          fontSize="13px"
          fontWeight={400}
        >
          {t("delivery-menu")}
        </Text>
      </Center>
      <Center
        bgColor={!isDelivery ? "#FFF" : "#323030"}
        w="100%"
        borderRadius="7px"
        onClick={handleRestClick}
        h="100%"
      >
        <Text
          color={!isDelivery ? "#323030" : "#FFF"}
          fontSize="13px"
          fontWeight={400}
        >
          {t("restaurant-menu")}
        </Text>
      </Center>
    </Center>
  );
}
