import { Box, Button, Center, Text } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { priceState } from "../../state/priceState";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function TotalSum() {
  const { t } = useTranslation("translations");
  const [totalPrice, setTotalPrice] = useRecoilState(priceState);

  const navigate = useNavigate();

  const handleOrderClick = () => {
    navigate("/cart");
  };

  return (
    <Box
      maxW="1175px"
      w="100%"
      bgColor="#1f1f1f"
      p="23px 38px 38px"
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      ml="auto"
      mr="auto"
    >
      <Center w="100%" pb="10px">
        <Text
          color="white"
          fontFamily="Sf Pro Display"
          fontWeight={500}
          fontSize="16px"
        >
          {t("total-sum-text")}
        </Text>
      </Center>
      <Center w="100%">
        <Button
          variant="white2"
          w={{ base: "100%", md: "300px" }}
          onClick={handleOrderClick}
        >
          {totalPrice} ₸
        </Button>
      </Center>
    </Box>
  );
}
