import { Icon } from "@chakra-ui/react";

export function WhatsappIcon(props) {
  const { width, height, ...rest } = props;
  return (
    <Icon
      width={width || "25px"}
      height={height || "25px"}
      viewBox="0 0 29 29"
      fill="none"
    >
      <path
        d="M14.5 0C6.48875 0 0 6.48875 0 14.5C0 17.69 1.015 20.6263 2.755 23.0188L0.76125 28.275L6.525 26.6075C8.80875 28.13 11.5638 29 14.5 29C22.5113 29 29 22.5113 29 14.5C29 6.48875 22.5113 0 14.5 0ZM22.2213 20.5175L20.6625 22.04C19.0312 23.6713 14.7175 21.895 10.9113 18.0525C7.105 14.2463 5.40125 9.9325 6.92375 8.3375L8.4825 6.77875C9.0625 6.19875 10.0413 6.19875 10.6575 6.77875L12.9413 9.0625C13.7388 9.86 13.4125 11.2375 12.3612 11.5638C11.6362 11.8175 11.1288 12.615 11.3825 13.34C11.7813 15.0437 13.9925 17.1463 15.6237 17.545C16.3487 17.7263 17.1825 17.2913 17.4 16.5663C17.7262 15.515 19.1038 15.1888 19.9013 15.9862L22.185 18.27C22.765 18.8863 22.765 19.865 22.2213 20.5175Z"
        fill="white"
      />
    </Icon>
  );
}
