import { Box, Center, Divider, Flex, Link } from "@chakra-ui/react";
import { Phoneicon } from "../../assets/icons/Phone";
import { WhatsappIcon } from "../../assets/icons/Whatsapp";
import { InstaIcon } from "../../assets/icons/Instagram";
import { FacebookIcon } from "../../assets/icons/Facebook";
import { GisIcon } from "../../assets/icons/Frame";
import { useTranslation } from "react-i18next";

export function LinksList() {
  const { t } = useTranslation("translations");

  return (
    <Flex
      flexDir={{ base: "column", md: "row" }}
      mt={{ base: "70px", md: "0" }}
    >
      <Flex
        gap={{ base: "20px", md: "20px" }}
        flexDir={{ base: "column", md: "row" }}
      >
        <Box>
          <Link
            color="white"
            fontFamily="Sf Pro Display"
            fontWeight={700}
            fontSize="26px"
            href="/menu"
          >
            {t("menu")}
          </Link>
          <Divider h="1px" colorScheme="whiteAlpha" pt="16px" opacity={0.06} />
        </Box>
        <Box>
          <Link
            color="white"
            fontFamily="Sf Pro Display"
            fontWeight={700}
            fontSize="26px"
            href="https://www.myadagio.kz"
          >
            {t("our-wine-library")}
          </Link>
          <Divider h="1px" colorScheme="whiteAlpha" pt="16px" opacity={0.06} />
        </Box>
        <Box>
          <Link
            color="white"
            fontFamily="Sf Pro Display"
            fontWeight={700}
            fontSize="26px"
          >
            {t("contacts")}
          </Link>
          <Divider h="1px" colorScheme="whiteAlpha" pt="16px" opacity={0.06} />
        </Box>
      </Flex>

      <Flex
        flexDir={{ base: "column", md: "row" }}
        mt={{ base: "38px", md: "0" }}
        gap="20px"
      >
        <Center justifyContent="flex-start" gap="10px">
          <Phoneicon />
          <Link
            color="white"
            fontFamily="Sf Pro Display"
            fontWeight={400}
            fontSize="22px"
            href="tel: +7 771 718 5619"
          >
            +7 771 718 5619
          </Link>
        </Center>
        <Center justifyContent="flex-start" gap="10px">
          <WhatsappIcon width="20px" height="20px" />
          <Link
            color="white"
            fontFamily="Sf Pro Display"
            fontWeight={400}
            fontSize="22px"
            href="http://Wa.me/77717185619"
          >
            +7 747 55 673 71
          </Link>
        </Center>
        <Center justifyContent="flex-start" gap="10px">
          <InstaIcon />
          <Link
            color="white"
            fontFamily="Sf Pro Display"
            fontWeight={400}
            fontSize="22px"
            href="https://instagram.com/proseccobar.kz?igshid=MzRlODBiNWFlZA=="
          >
            @proseccobar
          </Link>
        </Center>
        <Center justifyContent="flex-start" gap="10px">
          <FacebookIcon />
          <Link
            color="white"
            fontFamily="Sf Pro Display"
            fontWeight={400}
            fontSize="22px"
            href="https://m.facebook.com/proseccobar.kz?wtsid=rdr_04P3XQBPl4OJPu29h&_rdr"
          >
            proseccobar
          </Link>
        </Center>
        <Center justifyContent="flex-start" gap="10px">
          <GisIcon />
          <Link
            color="white"
            fontFamily="Sf Pro Display"
            fontWeight={400}
            fontSize="22px"
            href="https://2gis.kz/almaty/geo/70000001037439322"
          >
            Abylai khan 53
          </Link>
        </Center>
      </Flex>
    </Flex>
  );
}
