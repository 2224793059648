import { Box, Button, Center, Image, Link, Text } from "@chakra-ui/react";
import { Promotion } from "./Promotion";
import { Atmosphere } from "./Atmosphere";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useTranslation } from "react-i18next";
import { MenuElement } from "./Menu";
import { Events } from "./Events";
import { Header } from "../../components/Header/Header";
import { Map } from "@pbe/react-yandex-maps";
import { WhatsappIcon } from "../../assets/icons/Whatsapp";
import { InstaIcon } from "../../assets/icons/Instagram";
import { Phoneicon } from "../../assets/icons/Phone";
import { FacebookIcon } from "../../assets/icons/Facebook";
import { useNavigate } from "react-router-dom";

export default function MainPage() {
  const { t } = useTranslation("translations");

  const navigate = useNavigate();

  const navToMenu = () => {
    navigate("/menu");
  };

  const navToWhatsapp = () => {
    window.open("https://wa.me/77717185619");
  };

  return (
    <>
      <Header />
      <Image
        display={{ base: "block", md: "none" }}
        src="/assets/images/MainPageBgMobile.png"
        position="absolute"
        left="0"
        w="100%"
        h="494px"
        objectFit="contain"
      />
      <Image
        display={{ base: "none", md: "block" }}
        src="/assets/images/MainPageBgDesktop.png"
        position="absolute"
        left="0"
        w="100%"
        objectFit="contain"
      />
      <Box pt={{ base: "300px", md: "730px" }}>
        <Text
          position="relative"
          color="white"
          fontFamily="Pt Serif"
          fontWeight={700}
          fontSize="28px"
          fontStyle="italic"
        >
          {t("enjoy-delicious")}
        </Text>
        <Text
          position="relative"
          color="white"
          fontFamily="Sf Pro Display"
          fontWeight={500}
          fontSize="27px"
        >
          {t("food-and-atmosphere")}
        </Text>
        <Center justifyContent="satrt" gap="26px">
          <Button
            variant="orange"
            mt="28px"
            h="50px"
            w={{ base: " 100%", md: "320px" }}
            fontSize="20px"
            onClick={navToMenu}
          >
            {t("make-a-delivery")}
          </Button>
          <Button
            variant="black"
            mt="28px"
            h="50px"
            w="320px"
            fontSize="20px"
            display={{ base: "none", md: "flex" }}
            onClick={navToWhatsapp}
          >
            {t("book-a-table")}
          </Button>
        </Center>
      </Box>
      <Box mt="55px">
        <Events />
        <Promotion />
        <MenuElement />
        <Atmosphere />
      </Box>
      <PageHeading mt="40px" mb="20px">
        {t("address")}
      </PageHeading>
      <Center flexDir="column" borderRadius="50px">
        <Map
          width="100%"
          defaultState={{
            center: [43.264434647401956, 76.9397262811412],
            zoom: 17,
          }}
        />
      </Center>
      <Center pt="14px" justifyContent={{ base: "center", md: "flex-start" }}>
        <Text
          fontFamily="Sf Pro Display"
          fontWeight={400}
          fontSize="25px"
          color="white"
        >
          Abylai khan 53
        </Text>
      </Center>
      <Center gap="20px" pt="30px">
        <Link href="http://Wa.me/77717185619">
          <WhatsappIcon width="20px" height="20px"></WhatsappIcon>
        </Link>
        <Link href="https://instagram.com/proseccobar.kz?igshid=MzRlODBiNWFlZA==">
          <InstaIcon></InstaIcon>
        </Link>
        <Link href="tel:+7 771 718 5619">
          <Phoneicon></Phoneicon>
        </Link>
        <Link href="https://m.facebook.com/proseccobar.kz?wtsid=rdr_04P3XQBPl4OJPu29h&_rdr">
          <FacebookIcon></FacebookIcon>
        </Link>
      </Center>
    </>
  );
}
