const typography = {
  fonts: {
    heading: "SF Pro Display",
    body: "SF Pro Display",
    mono: "SF Pro Display",
  },
  baseStyle: {
    body: {
      color: "white",
    },
  },
};
export default typography;
