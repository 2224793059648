import { OrderPlaced } from "./OrderPlaced";
import { CartHeader } from "./CartHeader";
import { useRecoilState } from "recoil";
import { isOrderPlaceState } from "../../state/isOrderPlaced";
import { useEffect } from "react";

export default function SuccessPage() {
  const [isOrderPlaced, setIsOrderPlaced] = useRecoilState(isOrderPlaceState);

  useEffect(() => {
    setIsOrderPlaced(true);
    return () => {
      setIsOrderPlaced(false);
    };
  }, []);

  return (
    <>
      <CartHeader />
      <OrderPlaced />
    </>
  );
}
