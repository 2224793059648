import { useRecoilState } from "recoil";
import { priceState } from "../../state/priceState";
import { cartState } from "../../state/cart";
import { CartHeader } from "./CartHeader";
import { CheckoutForm } from "./CheckoutForm";
import { Box, Divider, Flex } from "@chakra-ui/react";
import { CartOrder } from "./CartOrder";
import { CartItem } from "./CartItem";
import { CartTotalSum } from "./CartTotalSum";
import { isOrderPlaceState } from "../../state/isOrderPlaced";
import { CartIsEmpty } from "./CartIsEmpty";

export default function CartPage() {
  const [cart, setCart] = useRecoilState(cartState);
  const [totalPrice, setTotalPrice] = useRecoilState(priceState);
  const [isOrderPlaced, setIsOrderPlaced] = useRecoilState(isOrderPlaceState);

  if (totalPrice === 0) return <CartIsEmpty />;

  return (
    <Box mb="200px">
      <CartHeader />
      <Box pt="100px">
        <CheckoutForm />
      </Box>
      <CartOrder />
      <Flex flexDir="column" gap="20px" mt="20px">
        {cart.map((item, index) => (
          <Box key={item.id}>
            <CartItem item={item} />
            {index !== cart?.length - 1 && (
              <Divider
                h="1px"
                colorScheme="whiteAlpha"
                py="10px"
                opacity={0.2}
              />
            )}
          </Box>
        ))}
      </Flex>
      <CartTotalSum />
    </Box>
  );
}
