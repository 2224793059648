import useSWR from "swr";
import { BASE_URL } from "../../store/store";
import { Button, Center, Image, Link, Text } from "@chakra-ui/react";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useTranslation } from "react-i18next";

export function Events() {
  const { t } = useTranslation("translations");

  const { data, isLoading, error } = useSWR(BASE_URL + "/api/main/get_event/");

  if (isLoading) return <div></div>;
  if (error) return <div></div>;
  if (!data) return <></>;

  return (
    <>
      <PageHeading mt="40px">{t("events")}</PageHeading>
      <Image
        mt="20px"
        borderRadius="22px"
        src={data?.image}
        w="100%"
        objectFit="contain"
      />
      <Center
        flexDir={{ base: "column", md: "row" }}
        gap="28px"
        justifyContent="space-between"
        w="100%"
        mt="12px"
        alignItems={{ base: "flex-start", md: "center" }}
      >
        <Text fontSize="23px" fontWeight={400} color="white">
          {data?.title}
        </Text>
        <Link href={data?.button_url} w={{ base: "100%", md: "420px" }}>
          <Button h="50px" w="100%">
            {data?.button_text}
          </Button>
        </Link>
      </Center>
    </>
  );
}
