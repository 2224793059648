import { MealFilter } from "../components/MealFilter/MealFilter";
import { MenuFilter } from "../components/MenuFilter/MenuFilter";
import { MenuItem } from "../components/MenuItem/MenuItem";
import { RestaurantMenuItem } from "../components/MenuItem/RestaurantMenuItem";

function Test() {
  return (
    <>
      <div>Test</div>
      <MenuFilter />
      <MealFilter />
      <MenuItem />
      <RestaurantMenuItem />
    </>
  );
}

export default Test;
