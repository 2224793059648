import { Center, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export function CounterBtn({ increasePrice, decreasePrice, price, count }) {
  const [cnt, setCnt] = useState(0);

  useEffect(() => {
    if (!!count && count > 0) setCnt(count);
  }, []);

  const handlePlus = () => {
    setCnt(cnt + 1);
    increasePrice(price);
  };

  const handleMinus = () => {
    setCnt(cnt - 1);
    decreasePrice(price);
  };

  if (cnt > 0) {
    return (
      <Center
        borderRadius="50px"
        bgColor="#2B2B2D"
        w="80px"
        justifyContent="space-around"
        h="26px"
        cursor="pointer"
      >
        <Center onClick={handleMinus} p="4px" cursor="pointer">
          <Text
            fontSize="11px"
            fontWeight={500}
            lineHeight="normal"
            color="white"
            cursor="pointer"
          >
            -
          </Text>
        </Center>
        <Center py="4px">
          <Text
            fontSize="11px"
            fontWeight={500}
            lineHeight="normal"
            color="white"
            cursor="pointer"
          >
            {cnt}
          </Text>
        </Center>
        <Center onClick={handlePlus} p="4px" cursor="pointer">
          <Text
            fontSize="11px"
            fontWeight={500}
            lineHeight="normal"
            color="white"
            cursor="pointer"
          >
            +
          </Text>
        </Center>
      </Center>
    );
  }

  return (
    <Center
      w="27px"
      h="27px"
      bgColor="#2B2B2D"
      borderRadius="50px"
      cursor="pointer"
    >
      <Text
        onClick={handlePlus}
        fontSize="18px"
        fontWeight={400}
        color="white"
        h="28px"
      >
        +
      </Text>
    </Center>
  );
}
