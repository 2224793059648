import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

export default function AppTemplate() {
  return (
    <>
      <Box bgColor="#030303" maxW="100%" minH="100vh">
        <Box px="25px" w="100%" pb="150px" maxW="1175px" mx="auto">
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
