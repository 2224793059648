import { Icon } from "@chakra-ui/react";

export function InstaIcon(props) {
  const { width, height, ...rest } = props;
  return (
    <Icon
      width={width || "25px"}
      height={height || "25px"}
      {...rest}
      iewBox="0 0 25 26"
      fill="none"
    >
      <path
        d="M12.5004 9.94408C10.6307 9.94408 9.11496 11.4598 9.11496 13.3295C9.11496 15.1992 10.6307 16.7149 12.5004 16.7149C14.3701 16.7149 15.8858 15.1992 15.8858 13.3295C15.8858 11.4598 14.3701 9.94408 12.5004 9.94408Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.05198 4.03936C10.6438 3.63792 14.357 3.63792 17.9488 4.03936C19.9263 4.26037 21.5213 5.81847 21.7534 7.8029C22.1828 11.4748 22.1828 15.1842 21.7534 18.8561C21.5213 20.8405 19.9263 22.3986 17.9488 22.6196C14.357 23.0211 10.6438 23.0211 7.05198 22.6196C5.07448 22.3986 3.47948 20.8405 3.24739 18.8561C2.81793 15.1842 2.81793 11.4748 3.24739 7.8029C3.47948 5.81847 5.07448 4.26037 7.05198 4.03936ZM17.7087 7.0795C17.1334 7.0795 16.667 7.54587 16.667 8.12116C16.667 8.69646 17.1334 9.16283 17.7087 9.16283C18.284 9.16283 18.7504 8.69646 18.7504 8.12116C18.7504 7.54587 18.284 7.0795 17.7087 7.0795ZM7.55246 13.3295C7.55246 10.5968 9.76772 8.38158 12.5004 8.38158C15.233 8.38158 17.4483 10.5968 17.4483 13.3295C17.4483 16.0622 15.233 18.2774 12.5004 18.2774C9.76772 18.2774 7.55246 16.0622 7.55246 13.3295Z"
        fill="white"
      />
    </Icon>
  );
}
