import { Icon } from "@chakra-ui/react";

export function Phoneicon(props) {
  const { width, height, ...rest } = props;
  return (
    <Icon
      width={width || "25px"}
      height={height || "25px"}
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        d="M5.20809 11.2308C7.20434 15.5786 10.7564 19.0354 15.1726 20.9093L15.8805 21.2247C17.5001 21.9463 19.404 21.3981 20.3919 19.9257L21.317 18.5469C21.6178 18.0985 21.5262 17.4947 21.106 17.1558L17.9687 14.6247C17.5079 14.253 16.8302 14.3396 16.4777 14.8153L15.5072 16.1249C13.0167 14.8964 10.9948 12.8745 9.76632 10.3841L11.076 9.41353C11.5517 9.061 11.6382 8.38339 11.2665 7.92257L8.73537 4.78513C8.39647 4.36504 7.79282 4.27341 7.34452 4.574L5.95614 5.50494C4.47453 6.49839 3.92934 8.41802 4.66761 10.0419L5.20728 11.229L5.20809 11.2308Z"
        fill="white"
      />
    </Icon>
  );
}
