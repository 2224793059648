import { Icon } from "@chakra-ui/react";

export function GisIcon(props) {
  const { width, height, ...rest } = props;
  return (
    <Icon
      width={width || "25px"}
      height={height || "25px"}
      {...rest}
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3.7627C17.1537 3.7627 20.3125 7.37065 20.3125 11.2627C20.3125 12.8252 19.9741 14.1889 19.1844 15.8934C14.5589 15.8934 13.7127 18.99 13.5153 21.0639L13.3743 22.5127H11.6257L11.4847 21.0639C11.2873 18.99 10.4411 15.8934 5.81566 15.8934C5.02595 14.1889 4.6875 12.8252 4.6875 11.2627C4.6875 7.37065 7.84633 3.7627 12.5 3.7627Z"
        fill="white"
      />
    </Icon>
  );
}
