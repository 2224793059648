import { Center, Text } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { activeSlugState } from "../../state/activeSlug";

export function MealFilterBtn({ title, slug }) {
  const [activeSlug, setActiveSlug] = useRecoilState(activeSlugState);

  return (
    <Center
      w={{ base: "fit-content", md: "270px" }}
      cursor="pointer"
      h={{ base: "30px", md: "40px" }}
      py="8px"
      px="20px"
      borderRadius="8px"
      bgColor={activeSlug === slug ? "#FFF" : "#030303"}
      onClick={() => setActiveSlug(slug)}
      border={activeSlug === slug ? "1px solid #030303" : "1px solid #FFF"}
    >
      <Text
        fontSize="13px"
        fontWeight={500}
        color={activeSlug === slug ? "#030303" : "#FFF"}
        whiteSpace="nowrap"
      >
        {title}
      </Text>
    </Center>
  );
}
