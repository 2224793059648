export default {
  baseStyle: {
    fontWeight: "500",
    borderRadius: "100px",
    height: "56px",
  },
  variants: {
    black: {
      backgroundColor: "#222222",
      color: "white",

      _hover: {
        backgroundColor: "white",
        color: "#222222",
        _disabled: {
          backgroundColor: "blue.200",
        },
      },

      _focus: {
        backgroundColor: "white",
        color: "#222222",
      },
    },
    white: {
      backgroundColor: "#AEAEAE",
      color: "black",

      _hover: {
        backgroundColor: "black",
        color: "#AEAEAE",
        _disabled: {
          backgroundColor: "blue.200",
        },
      },

      _focus: {
        backgroundColor: "black",
        color: "#AEAEAE",
      },
    },
    white2: {
      backgroundColor: "#fff",
      color: "black",

      _hover: {
        backgroundColor: "black",
        color: "#fff",

        _disabled: {
          backgroundColor: "blue.200",
        },
      },

      _focus: {
        backgroundColor: "black",
        color: "#fff",
      },
    },
    orange: {
      backgroundColor: "#A06C35",
      color: "white",

      _hover: {
        backgroundColor: "#D8852D",
        _disabled: {
          backgroundColor: "blue.200",
        },
      },

      _focus: {
        backgroundColor: "#D8852D",
      },
    },
  },

  defaultProps: {
    variant: "black",
    border: "none",
  },
};
