import { Box, Center } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MainDrawer } from "../../components/Header/MainDrawer";
import { CartIcon } from "../../assets/icons/CartIcon";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { priceState } from "../../state/priceState";
import { FilledCartIcon } from "../../assets/icons/FiiledCartIcon";
import { Logo } from "../../assets/icons/Logo";
import { useEffect } from "react";
import { cartState } from "../../state/cart";

export function MenuHeader() {
  const { t } = useTranslation("translations");
  const [totalPrice, setTotalPrice] = useRecoilState(priceState);
  const [cart, setCart] = useRecoilState(cartState);

  const navigate = useNavigate();

  useEffect(() => {
    setTotalPrice(0);
    setCart([]);
  }, []);

  const handleNavigate = () => {
    navigate("/cart");
  };

  return (
    <Center
      position="absolute"
      right={0}
      left={0}
      top={0}
      mx="auto"
      justifyContent="space-between"
      p={{ base: "16px 20px", md: "30px" }}
      bgColor={{ base: "#202020", md: "black" }}
      maxW="1175px"
    >
      <Box display={{ base: "block", md: "none" }}>
        <MainDrawer />
      </Box>
      <Logo />
      <Center gap="14px" onClick={handleNavigate} cursor="pointer">
        {totalPrice === 0 ? <CartIcon /> : <FilledCartIcon />}
      </Center>
    </Center>
  );
}
