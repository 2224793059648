import { EmptyCartHeader } from "./EmptyCartHeader";
import { Box, Button, Center, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function CartIsEmpty() {
  const { t } = useTranslation("translations");

  const navigate = useNavigate();

  return (
    <>
      <EmptyCartHeader />
      <Center flexDir="column" gap="25px" h="100vh">
        <Box>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="138"
            height="138"
            viewBox="0 0 138 138"
            fill="none"
          >
            <path
              d="M47.5238 2L23.3247 26.266"
              stroke="white"
              strokeWidth="2.69641"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M90.1733 2L114.372 26.266"
              stroke="white"
              strokeWidth="2.69641"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 41.1011C2 28.7342 8.61799 27.7314 16.8403 27.7314H120.856C129.079 27.7314 135.697 28.7342 135.697 41.1011C135.697 55.4735 129.079 54.4708 120.856 54.4708H16.8403C8.61799 54.4708 2 55.4735 2 41.1011Z"
              stroke="white"
              strokeWidth="2.69641"
            />
            <path
              d="M53.873 82.2178V105.949"
              stroke="white"
              strokeWidth="2.69641"
              strokeLinecap="round"
            />
            <path
              d="M84.625 82.2178V105.949"
              stroke="white"
              strokeWidth="2.69641"
              strokeLinecap="round"
            />
            <path
              d="M12.0273 55.4766L21.453 113.234C23.5921 126.202 28.7394 135.695 47.8581 135.695H88.1677C108.958 135.695 112.033 126.603 114.439 114.036L125.67 55.4766"
              stroke="white"
              strokeWidth="2.69641"
              strokeLinecap="round"
            />
          </svg>
          <Text
            color="white"
            fontFamily="Sf Pro Display"
            fontWeight={500}
            fontSize="20px"
          >
            {t("cart-is-empty")}
          </Text>
        </Box>
        <Button
          variant="white2"
          w={{ base: "100%", md: "fit-content" }}
          onClick={() => navigate("/menu")}
        >
          {t("view-menu")}
        </Button>
      </Center>
    </>
  );
}
