import { Box, Button, Center, Flex, Img, Text } from "@chakra-ui/react";
import { CounterBtn } from "../CounterBtn";
import { useRecoilState } from "recoil";
import { priceState } from "../../state/priceState";
import { cartState } from "../../state/cart";

export function MenuItem({ item, ...rest }) {
  const [totalPrice, setTotalPrice] = useRecoilState(priceState);
  const [cart, setCart] = useRecoilState(cartState);

  const increasePrice = (price) => {
    setTotalPrice(totalPrice + price);

    // Create a copy of the cart array
    const newCart = cart.map((cartItem) =>
      cartItem.id === item.id
        ? { ...cartItem, count: cartItem.count + 1 }
        : cartItem,
    );

    // If the item is not in the cart, add it with a count of 1
    const index = newCart.findIndex((cartItem) => cartItem.id === item.id);
    if (index === -1) {
      newCart.push({ ...item, count: 1 });
    }

    // Update the cart state with the modified cart array
    setCart(newCart);
  };

  const decreasePrice = (price) => {
    setTotalPrice(totalPrice - price);

    const newCart = cart.map((cartItem) =>
      cartItem.id === item.id
        ? { ...cartItem, count: cartItem.count - 1 }
        : cartItem,
    );

    // Remove the item if its count becomes 0
    const updatedCart = newCart.filter((cartItem) => cartItem.count > 0);

    // Update the cart state with the modified cart array
    setCart(updatedCart);
  };

  return (
    <Flex gap="17px" w="100%" alignItems="flex-start" {...rest}>
      <Img
        minW="101px"
        maxW="101px"
        h="101px"
        borderRadius="7px"
        bgColor="gray.200"
        src={item?.image}
      />
      <Box w="100%">
        <Text fontSize="18px" fontWeight={500} color="white">
          {item?.title}
        </Text>
        <Text
          mt="8px"
          fontSize="12px"
          fontWeight={400}
          color="white"
          lineHeight="120%"
        >
          {item?.description}
        </Text>

        <Center justifyContent="space-between" w="100%" mt="14px">
          <Button
            borderRadius="50px"
            fontSize="11px"
            fontWeight="500"
            variant="white"
            h="26px"
            w="90px"
            variant="white2"
            _hover={{
              bgColor: "#FFF",
              color: "#030303",
            }}
            _focus={{
              bgColor: "#FFF",
              color: "#030303",
            }}
          >
            {item?.price} ₸
          </Button>
          <CounterBtn
            increasePrice={increasePrice}
            decreasePrice={decreasePrice}
            price={item?.price}
          />
        </Center>
      </Box>
    </Flex>
  );
}
