import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { isOrderPlaceState } from "../../state/isOrderPlaced";
import { Box, Center } from "@chakra-ui/react";
import { MainDrawer } from "../../components/Header/MainDrawer";
import { Logo } from "../../assets/icons/Logo";
import { CartIcon } from "../../assets/icons/CartIcon";

export function EmptyCartHeader() {
  const { t } = useTranslation("translations");
  const [isOrderPlaced, setIsOrderPlaced] = useRecoilState(isOrderPlaceState);

  return (
    <Center
      zindex={800}
      position="absolute"
      right={0}
      left={0}
      top={0}
      mx="auto"
      justifyContent="space-between"
      p={{ base: "16px 20px", md: "40px" }}
      bgColor="#202020"
    >
      <Box display={{ base: "block", md: "none" }}>
        <MainDrawer />
      </Box>
      <Logo />

      <Center gap="14px">
        <CartIcon />
      </Center>
    </Center>
  );
}
