import { createBrowserRouter } from "react-router-dom";
import Test from "../pages/Test";
import AppTemplate from "../templates/AppTemplate";
import MainPage from "../pages/MainPage/MainPage";
import MenuPage from "../pages/Menu/MenuPage";
import CartPage from "../pages/Cart/Cart";
import SuccessPage from "../pages/Cart/Success";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppTemplate />,
    children: [
      {
        path: "/",
        element: <MainPage />,
      },
      {
        path: "/test",
        element: <Test />,
      },
      {
        path: "/menu",
        element: <MenuPage />,
      },
      {
        path: "/cart",
        element: <CartPage />,
      },
      {
        path: "/success",
        element: <SuccessPage />,
      },
    ],
  },
]);
