import { Center, Divider, Flex, Input, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { nameState } from "../../state/nameState";
import { phoneState } from "../../state/phoneState";
import { addressState } from "../../state/addressState";
import InputMask from "comigo-tech-react-input-mask";
import { isErrorState } from "../../state/isError";
import { useEffect, useRef } from "react";

export function CheckoutForm() {
  const { t } = useTranslation("translations");
  const [nameGlobal, setNameGlobal] = useRecoilState(nameState);
  const [phoneGlobal, setPhoneGlobal] = useRecoilState(phoneState);
  const [addressGlobal, setAddressGlobal] = useRecoilState(addressState);
  const [isError, setIsError] = useRecoilState(isErrorState);

  const firstInputRef = useRef(null); // Create a ref for the first input field

  useEffect(() => {
    // Check if isError is true and the ref is available
    if (isError && firstInputRef.current) {
      firstInputRef.current.focus(); // Set focus to the first input field
    }
  }, [isError]);

  return (
    <Flex flexDir="column" gap="5px">
      <Center justifyContent="space-between">
        <Text
          color="white"
          fontFamily="Sf Pro Display"
          fontWeight={500}
          fontSize="16px"
          w="50%"
        >
          {t("name")}
        </Text>
        <Input
          ref={firstInputRef}
          w="150px"
          onChange={(e) => {
            setIsError(false);

            setNameGlobal(e.target.value);
          }}
          type="text"
          fontFamily="Sf Pro Display"
          color="white"
          fontSize="16px"
          fontWeight={500}
          placeHolder="Akhmetova Raushan"
          border="none"
          _placeholder={{
            color: "#6A6A6A",
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: "Sf Pro Display",
            textAlign: "right",
          }}
          focusBorderColor="none"
          p="0"
        />
      </Center>
      <Divider h="1px" colorScheme="whiteAlpha" opacity={0.2} />
      <Center justifyContent="space-between">
        <Text
          color="white"
          fontFamily="Sf Pro Display"
          fontWeight={500}
          fontSize="16px"
          w="50%"
          whiteSpace="nowrap"
        >
          {t("phone-number")}
        </Text>
        <Input
          as={InputMask}
          mask="+7 999 999 99 99"
          w="140px"
          onChange={(e) => {
            setIsError(false);

            setPhoneGlobal(e.target.value);
          }}
          type="text"
          fontFamily="Sf Pro Display"
          color="white"
          fontSize="16px"
          fontWeight={500}
          placeHolder="+7 7ХХ ХХХ ХХ ХХ"
          border="none"
          _placeholder={{
            color: "#6A6A6A",
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: "Sf Pro Display",
            textAlign: "right",
          }}
          focusBorderColor="none"
          p="0"
        />
      </Center>
      <Divider h="1px" colorScheme="whiteAlpha" opacity={0.2} />
      <Center justifyContent="space-between">
        <Text
          color="white"
          fontFamily="Sf Pro Display"
          fontWeight={500}
          fontSize="16px"
          w="50%"
        >
          {t("address")}
        </Text>
        <Input
          w="150px"
          onChange={(e) => {
            setIsError(false);
            setAddressGlobal(e.target.value);
          }}
          type="text"
          fontFamily="Sf Pro Display"
          color="white"
          fontSize="16px"
          fontWeight={500}
          placeHolder="Abylai khan 53"
          border="none"
          _placeholder={{
            color: "#6A6A6A",
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: "Sf Pro Display",
            textAlign: "right",
          }}
          focusBorderColor="none"
          p="0"
        />
      </Center>
      {isError && (
        <Center justifyContent="flex-start" gap="5px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
          >
            <circle cx="8.48702" cy="8.63643" r="8.48702" fill="#FF4444" />
            <path
              d="M5.83447 5.9834L11.4043 11.2878"
              stroke="white"
              strokeWidth="1.35928"
              strokeLinecap="round"
            />
            <path
              d="M5.83447 5.9834L11.4043 11.2878"
              stroke="white"
              strokeWidth="1.35928"
              strokeLinecap="round"
            />
            <path
              d="M11.4048 5.9834L5.83491 11.2878"
              stroke="white"
              strokeWidth="1.35928"
              strokeLinecap="round"
            />
            <path
              d="M11.4048 5.9834L5.83491 11.2878"
              stroke="white"
              strokeWidth="1.35928"
              strokeLinecap="round"
            />
          </svg>
          <Text
            color="#F44"
            fontFamily="Sf Pro Display"
            fontWeight={400}
            fontSize="16px"
          >
            {t("error")}
          </Text>
        </Center>
      )}
    </Flex>
  );
}
