import { Center, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function OrderPlaced() {
  const { t } = useTranslation("translations");

  return (
    <Center
      position="fixed"
      w="100%"
      backdropFilter="blur(12px)"
      bgColor="rgba(0, 0, 0, 0.30)"
      zIndex={700}
      top={20}
      bottom={0}
      left={0}
      right={0}
      ml="auto"
      mr="auto"
      overflow="hidden"
    >
      <Center
        border="1px solid #fff"
        borderRadius="17px"
        p="30px 90px"
        flexDir="column"
        gap="5px"
        position="fixed"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="94"
          height="94"
          viewBox="0 0 94 94"
          fill="none"
        >
          <path
            d="M47.0002 86.1663C68.5418 86.1663 86.1668 68.5413 86.1668 46.9997C86.1668 25.458 68.5418 7.83301 47.0002 7.83301C25.4585 7.83301 7.8335 25.458 7.8335 46.9997C7.8335 68.5413 25.4585 86.1663 47.0002 86.1663Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.354 47.0002L41.4382 58.0843L63.6457 35.916"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Text
          fontFamily="Sf Pro Display"
          fontSize="18px"
          fontWeight={500}
          color="white"
        >
          {t("order-placed")}
        </Text>
      </Center>
    </Center>
  );
}
