import { MenuFilter } from "../../components/MenuFilter/MenuFilter";
import { MealFilter } from "../../components/MealFilter/MealFilter";
import { RestaurantMenuItem } from "../../components/MenuItem/RestaurantMenuItem";
import { MenuItem } from "../../components/MenuItem/MenuItem";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Icon,
  Skeleton,
} from "@chakra-ui/react";
import { isDeliveryState } from "../../state/isDelivery";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { BASE_URL } from "../../store/store";
import { activeSlugState } from "../../state/activeSlug";
import { useEffect } from "react";
import { TotalSum } from "../../components/TotalSum/TotalSum";
import { priceState } from "../../state/priceState";
import { MenuHeader } from "../MainPage/MenuHeader";

export default function MenuPage() {
  const [totalPrice, setTotalPrice] = useRecoilState(priceState);
  const [isDelivery, setIsDelivery] = useRecoilState(isDeliveryState);
  const [activeSlug, setActiveSlug] = useRecoilState(activeSlugState);
  const { t } = useTranslation("translations");

  const {
    data: sections,
    isLoading: isSectionsLoading,
    error: sectionError,
  } = useSWR(BASE_URL + `/api/menu/sections/?is_delivery=${isDelivery}`);

  useEffect(() => {
    if (sections !== undefined && sections?.length !== 0)
      setActiveSlug(sections?.[0].slug);
  }, [sections]);

  const { data, isLoading, error } = useSWR(
    BASE_URL +
      `/api/menu/menu/?can_be_delivered=${isDelivery}&section_slug=${activeSlug}`,
  );

  if (error || sectionError) return <div></div>;
  return (
    <>
      <MenuHeader />
      <Box pt="70px">
        <MenuFilter mt="25px" />
        {isSectionsLoading ? (
          <Center
            justifyContent="flex-start"
            w="100%"
            gap={{ base: "5px", md: "10px" }}
            overflowX="scroll"
            pb="25px"
            mt={{ base: "10px", md: "20xp" }}
          >
            <Skeleton
              h={{ base: "30px", md: "40px" }}
              py="8px"
              px="20px"
              borderRadius="8px"
              bgColor="#030303"
              border="1px solid #FFF"
              minW={{ base: "70px ", md: "80px" }}
            />
            <Skeleton
              h={{ base: "30px", md: "40px" }}
              py="8px"
              px="20px"
              borderRadius="8px"
              bgColor="#030303"
              border="1px solid #FFF"
              minW={{ base: "70px ", md: "80px" }}
            />
            <Skeleton
              h={{ base: "30px", md: "40px" }}
              py="8px"
              px="20px"
              borderRadius="8px"
              bgColor="#030303"
              border="1px solid #FFF"
              minW={{ base: "70px ", md: "80px" }}
            />
            <Skeleton
              h={{ base: "30px", md: "40px" }}
              py="8px"
              px="20px"
              borderRadius="8px"
              bgColor="#030303"
              border="1px solid #FFF"
              minW={{ base: "70px ", md: "80px" }}
            />
            <Skeleton
              h={{ base: "30px", md: "40px" }}
              py="8px"
              px="20px"
              borderRadius="8px"
              bgColor="#030303"
              border="1px solid #FFF"
              minW={{ base: "70px ", md: "80px" }}
            />
            <Skeleton
              h={{ base: "30px", md: "40px" }}
              py="8px"
              px="20px"
              borderRadius="8px"
              bgColor="#030303"
              border="1px solid #FFF"
              minW={{ base: "70px ", md: "80px" }}
            />
            <Skeleton
              h={{ base: "30px", md: "40px" }}
              py="8px"
              px="20px"
              borderRadius="8px"
              bgColor="#030303"
              border="1px solid #FFF"
              minW={{ base: "70px ", md: "80px" }}
            />
            <Skeleton
              h={{ base: "30px", md: "40px" }}
              py="8px"
              px="20px"
              borderRadius="8px"
              bgColor="#030303"
              border="1px solid #FFF"
              minW={{ base: "70px ", md: "80px" }}
            />
          </Center>
        ) : (
          <MealFilter mt={{ base: "10px", md: "20xp" }} sections={sections} />
        )}
        {isLoading ? (
          <div></div>
        ) : (
          <>
            <Accordion
              defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              allowToggle={true}
              allowMultiple={true}
            >
              {isDelivery &&
                data?.map((categoryMenu, index) => (
                  <AccordionItem border="none" key={index}>
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton px="0" w="100%">
                          <Center justifyContent="space-between" w="100%">
                            <PageHeading>{categoryMenu?.category}</PageHeading>
                            <Icon
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              fill="none"
                              transform={isExpanded ? "rotate(180deg)" : ""}
                              transition="transform 0.3s ease-in-out"
                            >
                              <path
                                d="M6 9L12 15L18 9"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                            </Icon>
                          </Center>
                        </AccordionButton>
                        <AccordionPanel
                          pt="20px"
                          pb={4}
                          px="0"
                          display="flex"
                          flexDir="column"
                          gap="20px"
                        >
                          {categoryMenu?.items?.map((item, index) => (
                            <Box key={index}>
                              <MenuItem item={item} />
                              {index !== categoryMenu?.items?.length - 1 && (
                                <Divider
                                  h="1px"
                                  colorScheme="whiteAlpha"
                                  py="10px"
                                  opacity={0.2}
                                />
                              )}
                            </Box>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}

              {!isDelivery &&
                data?.map((categoryMenu, index) => (
                  <AccordionItem border="none" key={index}>
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton px="0" w="100%">
                          <Center justifyContent="space-between" w="100%">
                            <PageHeading>{categoryMenu?.category}</PageHeading>
                            <Icon
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              fill="none"
                              transform={isExpanded ? "rotate(180deg)" : ""}
                              transition="transform 0.3s ease-in-out"
                            >
                              <path
                                d="M6 9L12 15L18 9"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                            </Icon>
                          </Center>
                        </AccordionButton>
                        <AccordionPanel
                          pb={4}
                          px="0"
                          display="flex"
                          flexDir="column"
                          gap="20px"
                        >
                          {categoryMenu?.items?.map((item, index) => (
                            <Box key={index}>
                              <RestaurantMenuItem item={item} key={index} />
                              {index !== categoryMenu?.items?.length - 1 && (
                                <Divider
                                  h="1px"
                                  colorScheme="whiteAlpha"
                                  py="10px"
                                  opacity={0.2}
                                />
                              )}
                            </Box>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
            </Accordion>
          </>
        )}
      </Box>
      {totalPrice !== 0 && <TotalSum />}
    </>
  );
}
