import { useState } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { getSelectedLanguage, setSelectedLanguage } from "../../helpers";

export function LanguageSelector() {
  const [selectedLanguage, setSelectedLanguageState] = useState(
    getSelectedLanguage(),
  );

  const handleLanguageChange = (language) => {
    setSelectedLanguageState(language);
    setSelectedLanguage(language);
    window.location.reload(); // Перезагрузить страницу после смены языка
  };

  const getTitle = () => {
    if (selectedLanguage === "en") return "En";
    if (selectedLanguage === "ru") return "Ру";
    if (selectedLanguage === "kz") return "Қа";
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        bgColor="transparent"
        px="0px"
        _hover={{
          bgColor: "black",
          color: "white",
        }}
        _focus={{
          bgColor: "black",
          color: "white",
        }}
      >
        <Text
          color="white"
          fontFamily="Sf Pro Display"
          fontWeight={500}
          fontSize="18px"
        >
          {getTitle()}
        </Text>
      </MenuButton>
      <MenuList
        bgColor="#252525"
        border="none"
        p="7px"
        borderRadius="16px"
        maxW={{ base: " 165px", md: "140px" }}
      >
        <MenuItem
          bgColor="#252525"
          color="white"
          onClick={() => handleLanguageChange("en")}
          justifyContent="center"
          fontSize="16px"
          fontWeight={400}
          py="15px"
          borderBottom="1px solid rgba(255, 255, 255, 0.1)"
        >
          English
        </MenuItem>
        <MenuItem
          bgColor="#252525"
          color="white"
          onClick={() => handleLanguageChange("ru")}
          justifyContent="center"
          fontSize="16px"
          fontWeight={400}
          py="15px"
          borderBottom="1px solid rgba(255, 255, 255, 0.1)"
        >
          Русский
        </MenuItem>
        <MenuItem
          bgColor="#252525"
          color="white"
          onClick={() => handleLanguageChange("kz")}
          justifyContent="center"
          fontSize="16px"
          fontWeight={400}
          py="15px"
        >
          Қазақша
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
