import { Center } from "@chakra-ui/react";
import { MealFilterBtn } from "./MealFilterBtn";

export function MealFilter({ sections, ...rest }) {
  return (
    <Center
      justifyContent="flex-start"
      w="100%"
      gap={{ base: "5px", md: "10px" }}
      overflowX="scroll"
      pb="25px"
      {...rest}
    >
      {sections?.map((section, index) => (
        <MealFilterBtn title={section.title} slug={section.slug} key={index} />
      ))}
    </Center>
  );
}
