import { Box, Button, Center, Text } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { priceState } from "../../state/priceState";

export function RestaurantMenuItem({ item, ...rest }) {
  const [totalPrice, setTotalPrice] = useRecoilState(priceState);

  const increasePrice = (price) => {
    setTotalPrice(totalPrice + price);
  };

  const decreasePrice = (price) => {
    setTotalPrice(totalPrice - price);
  };

  return (
    <Box {...rest}>
      <Text fontSize="18px" fontWeight={500} color="white">
        {item?.title}
      </Text>
      <Text
        mt="8px"
        fontSize="12px"
        fontWeight={400}
        color="white"
        lineHeight="120%"
      >
        {item?.description}
      </Text>

      <Center justifyContent="space-between" w="100%" mt="14px">
        <Button
          borderRadius="50px"
          fontSize="11px"
          fontWeight="500"
          h="26px"
          w="90px"
          variant="black"
          _hover={{
            bgColor: "#222222",
            color: "#fff",
          }}
          _focus={{
            bgColor: "#222222",
            color: "#fff",
          }}
        >
          {item?.price} ₸
        </Button>
      </Center>
    </Box>
  );
}
