import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";

export function CartOrder() {
  const { t } = useTranslation("translations");

  return (
    <Box mt="20px">
      <PageHeading>{t("your-order")}</PageHeading>
    </Box>
  );
}
