import { theme } from "@chakra-ui/react";

const sizes = {
  ...theme.sizes,
  container: {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1180px",
  },
};

export default sizes;
