const breakpoints = {
  sm: "30em", // 480px
  md: "48em", // 768px
  lg: "62em", // 992px
  xl: "85em", // 1350px
  "2xl": "96em", // 1536px
  "3xl": "120em", // 1920px
  "4xl": "144em", // 2304px
};

export default breakpoints;
