import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/router";
import { theme } from "./theme/theme";
import { YMaps } from "@pbe/react-yandex-maps";

import { SWRConfig } from "swr";
import axios from "axios";

import "./i18n";
import { BASE_URL } from "./store/store";
import { getSelectedLanguage } from "./helpers";
import { RecoilRoot } from "recoil";

import "./App.css";

const request = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Accept-Language": getSelectedLanguage(),
  },
});

function App() {
  return (
    <YMaps>
      <RecoilRoot>
        <SWRConfig
          value={{
            fetcher: (url) => request.get(url).then((res) => res.data),
          }}
        >
          <ChakraProvider theme={theme}>
            <RouterProvider router={router} />
          </ChakraProvider>
        </SWRConfig>
      </RecoilRoot>
    </YMaps>
  );
}

export default App;
